<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <span class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</span>
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>
                {{ csvRecords.length }}件中{{ importCount }}件の取り込みが完了しました。 (一括取込番号：{{
                  importNumber
                }})
              </p>
              <p v-if="errorRows.length > 0">入荷実績情報の取込エラーがあります。確認してください。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete(false)">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="入荷実績一括取込"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import FileUtils from "../../utils/FileUtils";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import axios from "axios";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "ArrivalAchievementImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data: () => ({
    step: 1,
    file: null,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      columnDefs: [
        { headerName: "行番号", field: "rowNumber" },
        { headerName: "荷主CD", field: "shipperCode" },
        { headerName: "倉庫CD", field: "warehouseCode" },
        { headerName: "入庫検品実績番号", field: "receiptInspectionAchievementNumber" },
        { headerName: "入庫検品日", field: "receiptInspectionDate", type: "dpDateColumn" },
        { headerName: "入荷予定番号", field: "arrivalPlanNumber" },
        { headerName: "入荷予定日", field: "arrivalPlanDate", type: "dpDateColumn" },
        { headerName: "入荷種別", field: "receiptType" },
        { headerName: "発生元部署CD", field: "originDepartmentCode" },
        { headerName: "発生元部署名", field: "originDepartmentName" },
        { headerName: "発注番号", field: "purchaseId" },
        { headerName: "発生日", field: "accrualDate", type: "dpDateColumn" },
        { headerName: "納品書番号", field: "deliveryReportNumber" },
        { headerName: "納品書日付", field: "deliveryReportDate" },
        { headerName: "訂正理由", field: "correctionReason" },
        { headerName: "返品理由", field: "ReturnReason" },
        { headerName: "ヘッダー備考", field: "headerRemark" },
        { headerName: "入荷取引先CD", field: "receiptCustomerCode" },
        { headerName: "入荷予定明細番号", field: "receiptScheduleDetailsNumber" },
        { headerName: "入庫検品実績明細番号", field: "receiptInspectionAchievementDetailsNumber" },
        { headerName: "商品CD", field: "productCode" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "商品名", field: "productName" },
        { headerName: "大分類区分", field: "majorClassType" },
        { headerName: "大分類区分名", field: "majorClassName" },
        { headerName: "中分類区分", field: "middleClassType" },
        { headerName: "中分類区分名", field: "middleClassName" },
        { headerName: "小分類区分", field: "subcategoryType" },
        { headerName: "小分類区分名", field: "subcategoryName" },
        { headerName: "商品備考", field: "productRemarks" },
        { headerName: "荷姿区分", field: "packingType" },
        { headerName: "ロケーションCD", field: "locationCode" },
        { headerName: "状態CD", field: "conditionCode" },
        { headerName: "ロット番号", field: "lotNumber" },
        { headerName: "賞味期限", field: "sellBy", type: "dpDateColumn" },
        { headerName: "入庫日", field: "receiptDate", type: "dpDateColumn" },
        { headerName: "入庫予定数量", field: "receiptScheduleQuantity", type: "dpNumericColumn" },
        { headerName: "検品実績数量", field: "inspectionAchievementQuantity", type: "dpNumericColumn" },
        { headerName: "本体金額", field: "amount", type: "dpNumericColumn" },
        { headerName: "消費税金額", field: "consumptionTax", type: "dpNumericColumn" },
        { headerName: "明細備考", field: "detailsRemark" },
        { headerName: "シリアル番号", field: "serialNumber" },
      ],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
    },
    csvRecords: [],
    importNumber: null,
    validImportForm: null,
    rules: {
      required: Validation.required,
      isNumber: Validation.isNumber,
      maxByteLength: Validation.maxByteLength,
      isCsv: Validation.isCsv,
      maxRateLength: Validation.maxRateLength,
      maxLength: Validation.maxLength,
      maxByteLengthSjis: Validation.maxByteLengthSjis,
    },
    importCount: 0,
    errorColmuns: [
      { headerName: "行番号", field: "rowNumber" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
    errorRows: [],
  }),
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          const csvText = await FileUtils.readAsText(this.file, true);
          const csvLines = csvText.split("\n").map((line) => line.split(",").map((filed) => filed.trim()));
          this.csvRecords = [];
          csvLines.forEach((line, index) => {
            if (index !== 0 && line.length > 1) {
              this.csvRecords.push({
                rowNumber: index,
                shipperCode: line[0],
                warehouseCode: line[1],
                receiptInspectionAchievementNumber: line[2],
                receiptInspectionDate: line[3],
                arrivalPlanNumber: line[4],
                arrivalPlanDate: line[5],
                receiptType: line[6],
                originDepartmentCode: line[7],
                originDepartmentName: line[8],
                purchaseId: line[9],
                accrualDate: line[10],
                deliveryReportNumber: line[11],
                deliveryReportDate: line[12],
                correctionReason: line[13],
                ReturnReason: line[14],
                headerRemark: line[15],
                receiptCustomerCode: line[16],
                receiptScheduleDetailsNumber: line[17],
                receiptInspectionAchievementDetailsNumber: line[18],
                productCode: line[19],
                janCode: line[20],
                productName: line[21],
                majorClassType: line[22],
                majorClassName: line[23],
                middleClassType: line[24],
                middleClassName: line[25],
                subcategoryType: line[26],
                subcategoryName: line[27],
                productRemarks: line[28],
                packingType: line[29],
                locationCode: line[30],
                conditionCode: line[31],
                lotNumber: line[32],
                sellBy: line[33],
                receiptDate: line[34],
                receiptScheduleQuantity: line[35],
                inspectionAchievementQuantity: line[36],
                amount: line[37],
                consumptionTax: line[38],
                detailsRemark: line[39],
                serialNumber: line[40],
              });
            }
          });
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    async onSubmit() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.csvRecords.forEach((record) => {
        var error = this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            rowNumber: record.rowNumber,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      });
      if (isValid) {
        try {
          this.loadingOn();
          // 最大件数チェック
          const validDataSizeRet = await RequestUtils.validDataSize("C040302", requestRecords.length, (limitSize) => {
            this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
              timeout: 2300,
            });
            this.loadingOff();
          });
          if (!validDataSizeRet) {
            return;
          }
          this.errorRows = [];
          this.importCount = 0;
          this.importNumber = null;
          let url = "";
          var param = {
            importNumber: this.importNumber,
            arrivalAchievements: requestRecords,
            filePath: this.file.name,
          };
          const response = await this.$store.dispatch("arrivalAchievement/import", param);
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.importNumber = response.data.contents.importNumber;
              this.importCount += response.data.contents.count;
              url = response.data.contents.url;
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    rowNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              this.importCount += response.data.contents.count;
              this.importNumber = this.importCount > 0 ? response.data.contents.importNumber : null;
              url = response.data.contents.url;
              break;
            default:
              this.redirectError();
              break;
          }
          if (this.errorRows.length > 0) {
            this.$refs.importErrorGrid.open({ records: this.errorRows });
          } else {
            this.$refs.importErrorGrid.close();
          }

          this.csvUpload(url);
          this.step++;
        } catch (error) {
          console.error("ArrivalAchievementImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.importErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];

      this.setValidMessage(this.rules.maxLength(100)(row.shipperCode), "荷主CD", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.warehouseCode), "倉庫CD", messages);

      // 入庫検品実績番号
      this.setValidMessage(this.rules.required(row.receiptInspectionAchievementNumber), "入庫検品実績番号", messages);
      this.setValidMessage(
        this.rules.maxLength(100)(row.receiptInspectionAchievementNumber),
        "入庫検品実績番号",
        messages
      );
      // 入庫検品日
      this.setValidMessage(this.rules.required(row.receiptInspectionDate), "入庫検品日", messages);
      this.setValidMessage(this.isDate(row.receiptInspectionDate), "入庫検品日", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.arrivalPlanNumber), "入荷予定番号", messages);

      // 入荷予定日
      this.setValidMessage(this.rules.required(row.arrivalPlanDate), "入荷予定日", messages);
      this.setValidMessage(this.isDate(row.arrivalPlanDate), "入荷予定日", messages);

      this.setValidMessage(this.rules.maxLength(100)(row.receiptType), "入荷種別", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.originDepartmentCode), "発生元部署CD", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.originDepartmentName), "発生元部署名", messages);
      this.setValidMessage(this.rules.maxLength(12)(row.purchaseId), "発注番号", messages);

      // 発生日
      this.setValidMessage(this.isDate(row.accrualDate), "発生日", messages);

      this.setValidMessage(this.rules.maxLength(100)(row.deliveryReportNumber), "納品書番号", messages);

      // 納品書日付
      this.setValidMessage(this.isDate(row.deliveryReportDate), "納品書日付", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.correctionReason), "訂正理由", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.ReturnReason), "返品理由", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.headerRemark), "ヘッダー備考", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.receiptCustomerCode), "入荷取引先CD", messages);

      // 入荷予定明細番号
      this.setValidMessage(this.rules.isNumber(row.receiptScheduleDetailsNumber), "入荷予定明細番号", messages);
      this.setValidMessage(this.rules.maxLength(9)(row.receiptScheduleDetailsNumber), "入荷予定明細番号", messages);

      // 入庫検品実績明細番号
      this.setValidMessage(
        this.rules.isNumber(row.receiptInspectionAchievementDetailsNumber),
        "入庫検品実績明細番号",
        messages
      );
      this.setValidMessage(
        this.rules.maxLength(9)(row.receiptInspectionAchievementDetailsNumber),
        "入庫検品実績明細番号",
        messages
      );

      // 商品CD
      // this.setValidMessage(this.rules.isNumber(row.productCode), "商品CD", messages);
      // JANCODE
      this.setValidMessage(this.rules.required(row.janCode), "JANCODE", messages);
      this.setValidMessage(this.rules.maxLength(13)(row.janCode), "JANCODE", messages);

      // 商品名
      this.setValidMessage(this.rules.maxByteLengthSjis(60)(row.productName), "商品名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.majorClassType), "大分類区分", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.majorClassName), "大分類区分名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.middleClassType), "中分類区分", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.middleClassName), "中分類区分名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.subcategoryType), "小分類区分", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.subcategoryName), "小分類区分名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.productRemarks), "商品備考", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.packingType), "荷姿区分", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.locationCode), "ロケーションCD", messages);

      // 状態CD
      this.setValidMessage(this.rules.required(row.conditionCode), "状態CD", messages);
      this.setValidMessage(this.rules.isNumber(row.conditionCode), "状態CD", messages);
      this.setValidMessage(this.rules.maxLength(9)(row.conditionCode), "状態CD", messages);

      // ロット番号
      this.setValidMessage(this.rules.maxLength(100)(row.lotNumber), "ロット番号", messages);

      // 賞味期限
      this.setValidMessage(this.isDate(row.sellBy), "賞味期限", messages);
      // 入庫日
      this.setValidMessage(this.isDate(row.receiptDate), "入庫日", messages);
      // 入庫予定数量
      this.setValidMessage(this.rules.isNumber(row.receiptScheduleQuantity), "入庫予定数量", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.receiptScheduleQuantity), "入庫予定数量", messages);

      // 検品実績数量
      this.setValidMessage(this.rules.required(row.inspectionAchievementQuantity), "検品実績数量", messages);
      this.setValidMessage(this.rules.isNumber(row.inspectionAchievementQuantity), "検品実績数量", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.inspectionAchievementQuantity), "検品実績数量", messages);

      // 本体金額
      this.setValidMessage(this.rules.isNumber(row.amount), "本体金額", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.amount), "本体金額", messages);

      // 消費税金額
      this.setValidMessage(this.rules.isNumber(row.consumptionTax), "消費税金額", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.consumptionTax), "消費税金額", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.detailsRemark), "明細備考", messages);

      // シリアル番号
      this.setValidMessage(this.rules.maxLength(100)(row.serialNumber), "シリアル番号", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    requestFormat(row) {
      return {
        shipperCode: this.stringFormat(row.shipperCode),
        warehouseCode: this.stringFormat(row.warehouseCode),
        receiptInspectionAchievementNumber: this.stringFormat(row.receiptInspectionAchievementNumber),
        receiptInspectionDate: this.stringDateFormat(row.receiptInspectionDate),
        arrivalPlanNumber: this.stringFormat(row.arrivalPlanNumber),
        arrivalPlanDate: this.stringDateFormat(row.arrivalPlanDate),
        receiptType: this.stringFormat(row.receiptType),
        originDepartmentCode: this.stringFormat(row.originDepartmentCode),
        originDepartmentName: this.stringFormat(row.originDepartmentName),
        purchaseId: this.stringFormat(row.purchaseId),
        accrualDate: this.stringDateFormat(row.accrualDate),
        deliveryReportNumber: this.stringFormat(row.deliveryReportNumber),
        deliveryReportDate: this.stringDateFormat(row.deliveryReportDate),
        correctionReason: this.stringFormat(row.correctionReason),
        ReturnReason: this.stringFormat(row.ReturnReason),
        headerRemark: this.stringFormat(row.headerRemark),
        receiptCustomerCode: this.stringFormat(row.receiptCustomerCode),
        receiptScheduleDetailsNumber: this.numberFormat(row.receiptScheduleDetailsNumber),
        receiptInspectionAchievementDetailsNumber: this.numberFormat(row.receiptInspectionAchievementDetailsNumber),
        janCode: this.stringFormat(row.janCode),
        productName: this.stringFormat(row.productName),
        majorClassType: this.stringFormat(row.majorClassType),
        majorClassName: this.stringFormat(row.majorClassName),
        middleClassType: this.stringFormat(row.middleClassType),
        middleClassName: this.stringFormat(row.middleClassName),
        subcategoryType: this.stringFormat(row.subcategoryType),
        subcategoryName: this.stringFormat(row.subcategoryName),
        productRemarks: this.stringFormat(row.productRemarks),
        packingType: this.stringFormat(row.packingType),
        locationCode: this.stringFormat(row.locationCode),
        conditionCode: this.stringFormat(row.conditionCode),
        lotNumber: this.stringFormat(row.lotNumber),
        sellBy: this.stringFormat(row.sellBy),
        receiptDate: this.stringDateFormat(row.receiptDate),
        receiptScheduleQuantity: this.numberFormat(row.receiptScheduleQuantity),
        inspectionAchievementQuantity: this.numberFormat(row.inspectionAchievementQuantity),
        amount: this.numberFormat(row.amount),
        consumptionTax: this.numberFormat(row.consumptionTax),
        detailsRemark: this.stringFormat(row.detailsRemark),
        serialNumber: this.stringFormat(row.serialNumber),
        rowNumber: this.numberFormat(row.rowNumber),
      };
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(value).format("YYYY-MM-DD");
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY/MM/DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    async csvUpload(url) {
      // CSVファイルのアップロード
      const buffer = await FileUtils.readAsArrayBuffer(this.file);
      await axios.put(url, buffer, { headers: { "Content-Type": this.file.type } });
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
};
</script>
