<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-database-search</v-icon>在庫表</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
            <search-conditions @search="onBtnSearch" max-height="100%">
              <v-row dense>
                <v-col>
                  <v-checkbox label="検索上限無し" v-model="searchModel.fetchLimitOverFlg" dense></v-checkbox
                ></v-col>
                <v-col>
                  <v-checkbox label="在庫有のみ" v-model="searchModel.isStock" dense></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <dp-date-picker
                  type="date"
                  label="在庫断面"
                  v-model="searchModel.billingDateFrom"
                  dense
                ></dp-date-picker
              ></v-row>
              <v-row dense>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.supplierShortName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="仕入先略称"
                  dense
                ></v-text-field>
              </v-row>
              <v-row dense>
                <v-textarea
                  v-model="searchModel.jancodeList"
                  class="mx-2"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                  label="JANコード"
                  rows="1"
                  dense
                ></v-textarea>
              </v-row>
              <v-row dense>
                <v-text-field
                  v-model="searchModel.productName"
                  label="商品名"
                  dense
                  class="mx-2"
                  :rules="[rules.maxLength(60)]"
                ></v-text-field>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="searchModel.salesAgencyName"
                    label="発売元"
                    dense
                    class="mx-2"
                    :rules="[rules.maxLength(150)]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="searchModel.salesOriginName"
                    label="販売元"
                    dense
                    class="mx-2"
                    :rules="[rules.maxLength(150)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <cafereo-user-field
                  ref="customerStaff"
                  label="仕入担当者"
                  v-model="searchModel.supplierChargeCD"
                  :attach="false"
                ></cafereo-user-field>
              </v-row>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            :disabled="!enabledUpdate"
            icon="mdi-database-edit"
            @click="onUpdateClick"
            v-if="allowedAction(['C040102'])"
            >在庫更新</tooltip-icon-button
          >
          <v-dialog v-model="updateDialog" max-width="600px" persistent>
            <stock-edit
              ref="stockEdit"
              v-if="updateDialog"
              :inputModel="updateModel"
              @cancel="updateDialog = false"
              @updated="onUpdated"
            ></stock-edit>
          </v-dialog>
          <tooltip-icon-button
            :disabled="!enabledTransfer"
            icon="mdi-transfer"
            @click="onTransferClick"
            v-if="allowedAction(['C040103'])"
            >在庫移動登録</tooltip-icon-button
          >
          <v-dialog v-model="transferDialog" max-width="1000px" persistent>
            <stock-move-entry
              ref="stockMoveEntry"
              :inputModel="transferModel"
              v-if="transferDialog"
              @cancel="transferDialog = false"
              @transfered="onTransfered"
            ></stock-move-entry>
          </v-dialog>
          <tooltip-icon-button
            :disabled="!enabledBadTransfer"
            icon="mdi-swap-horizontal"
            @click="onBadTransferClick"
            v-if="allowedAction(['C040105'])"
            >在庫移管</tooltip-icon-button
          >
          <v-dialog v-model="badTransferDialog" max-width="600px" persistent>
            <bad-stock-move-edit
              ref="badStockMoveEdit"
              :inputModel="badTransferModel"
              v-if="badTransferDialog"
              @cancel="badTransferDialog = false"
              @transfered="onBadTransfered"
            ></bad-stock-move-edit>
          </v-dialog>
          <tooltip-icon-button
            v-if="allowedAction(['C040104'])"
            :disabled="!enabledCsvExport"
            icon="mdi-download"
            @click="onExportClick"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <v-row>
          <v-col cols="2"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >在庫金額：￥{{ this.stockAmountTotal.toLocaleString() }}</v-card-text
              ></v-card
            ></v-col
          ><v-col cols="2"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >[選択]在庫金額：￥{{ this.selectStockAmountTotal.toLocaleString() }}</v-card-text
              ></v-card
            ></v-col
          ><v-col cols="3"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >在庫金額（委託在庫(当社)）：￥{{ this.crStockAmountTotal.toLocaleString() }}</v-card-text
              ></v-card
            ></v-col
          ><v-col cols="3"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >在庫金額（委託在庫(MK)）：￥{{ this.mkStockAmountTotal.toLocaleString() }}</v-card-text
              ></v-card
            ></v-col
          ><v-col cols="2"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >事務所在庫金額：￥{{ this.officeStockAmountTotal.toLocaleString() }}</v-card-text
              ></v-card
            ></v-col
          >
          <v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="stockRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          @selection-changed="onSelectionChanged"
          @filter-changed="calcTotalItem"
        ></ag-grid-vue>
      </v-col>
      <v-col v-if="shownInfo" id="StockInfos" style="flex-basis: auto; display: flex" :cols="infoMaximum ? 12 : 3">
        <v-divider vertical></v-divider>
        <stock-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :janCode="selectedRow != null ? selectedRow.janCode : null"
          :storageCode="selectedRow != null ? selectedRow.storageCode : null"
          :stockId="selectedRow != null ? selectedRow.stockId : null"
        ></stock-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import StockInfos from "./../../components/stock/StockInfos.vue";
import StockEdit from "./../../components/stock/StockEdit.vue";
import StockMoveEntry from "./../../components/stock/StockMoveEntry.vue";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import ProductTypes from "../../consts/ProductTypes";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import BadStockMoveEdit from "./../../components/stock/BadStockMoveEdit.vue";
import Storages from "../../consts/Storages";
import Validation from "../../libs/validation";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";

export default {
  name: "StockSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    StockInfos,
    StockEdit,
    StockMoveEntry,
    BadStockMoveEdit,
    CafereoUserField,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      shownInfo: false,
      infoMaximum: false,
      activeTab: null,
      selectedPageSize: 10,
      validSeearchForm: null,
      rules: {
        maxLength: Validation.maxLength,
        required: Validation.required,
        dateFrom: (value) => this.dateFromRules(value),
        dateTo: (value) => this.dateToRules(value),
        isJancodeSearchList: Validation.isJancodeSearchList,
      },
      gridOptions: {
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpFullDateColumn: FullDateColumn,
          dpDateColumn: DateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
          },
          {
            headerName: "商品ステータス",
            field: "productStatus",
            filter: "dpSelectionFilter",
            filterParams: { options: CafereoProductStatus.all() },
            valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
          },
          {
            headerName: "商品区分",
            field: "productType",
            filter: "dpSelectionFilter",
            filterParams: { options: ProductTypes.all() },
            valueGetter: (params) => ProductTypes.of(params.data.productType),
          },
          {
            headerName: "仕入先略称",
            field: "makerName",
          },
          { headerName: "商品ランク", field: "productLank" },
          { headerName: "JANCODE", field: "janCode" },
          { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
          { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
          { headerName: "発売元", field: "salesAgencyName" },
          { headerName: "販売元", field: "salesOriginName" },
          { headerName: "保管場所", field: "storageName" },
          {
            headerName: "在庫総数",
            field: "stockTotalQuantity",
            type: "dpNumericColumn",
            valueGetter: (params) => {
              return (
                (params.data.stockQuantity ? params.data.stockQuantity : 0) +
                (params.data.ensureQuantity ? params.data.ensureQuantity : 0) +
                (params.data.badQuantity ? params.data.badQuantity : 0) +
                (params.data.cafereoEntrustQuantity ? params.data.cafereoEntrustQuantity : 0) +
                (params.data.makerEntrustQuantity ? params.data.makerEntrustQuantity : 0)
              );
            },
          },
          {
            headerName: "在庫数",
            field: "stockQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "確保在庫数",
            field: "ensureQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "不良在庫数",
            field: "badQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "委託在庫(当社)",
            field: "cafereoEntrustQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "委託在庫(MK)",
            field: "makerEntrustQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "移動予定数",
            field: "movingQuantity",
            type: "dpNumericColumn",
          },
          { headerName: "未出荷受注数", field: "unshipedOrderTotalQuantity", type: "dpNumericColumn" },
          { headerName: "未入荷発注数", field: "unarrivaledPurchaseTotalQuantity", type: "dpNumericColumn" },
          {
            headerName: "在庫金額",
            field: "movingAmount",
            type: "dpNumericColumn",
          },
          {
            headerName: "上代",
            field: "retailPrice",
            type: "dpNumericColumn",
          },
          {
            headerName: "仕入価格",
            field: "purchasePrice",
            type: "dpNumericColumn",
          },
          {
            headerName: "仕入掛率",
            field: "purchaseRate",
            type: "dpPercentColumn",
          },
          { headerName: "発売日", field: "releaseDate" },
          {
            headerName: "受注単位",
            field: "orderUnit",
            type: "dpNumericColumn",
          },
          {
            headerName: "上限数",
            field: "maximumNumber",
            type: "dpNumericColumn",
          },
          { headerName: "メーカー在庫", field: "makerStock", type: "dpCheckmarkColumn" },
          { headerName: "初回締後追加不可", field: "nonAdded", type: "dpCheckmarkColumn" },
          { headerName: "食品", field: "confectionery", type: "dpCheckmarkColumn" },
          { headerName: "賞味期限", field: "sellBy", type: "dpDateColumn" },
          { headerName: "ASIN", field: "asin" },
          {
            headerName: "Box入数",
            field: "inBoxQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "CT中Box入数",
            field: "inCtBoxQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "CT中pcs入数",
            field: "inCtPcsQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "CTsizeW",
            field: "ctSizeWidth",
            type: "dpNumericColumn",
          },
          {
            headerName: "CTsizeD",
            field: "ctSizeDepth",
            type: "dpNumericColumn",
          },
          {
            headerName: "CTsizeH",
            field: "ctSizeHeight",
            type: "dpNumericColumn",
          },
          { headerName: "仕入担当者", field: "supplierChargeName" },
          { headerName: "登録日時", field: "createDatetime", filter: "agDateColumnFilter", type: "dpDateColumn" },
          { headerName: "登録者", field: "createUser" },
          { headerName: "更新日時", field: "updateDatetime", filter: "agDateColumnFilter", type: "dpDateColumn" },
          { headerName: "更新者", field: "updateUser" },
          { headerName: "在庫ID", field: "stockId", hide: true },
          { headerName: "倉庫CD", field: "storageCode", hide: true },
        ],
        rowSelection: "multiple",
        suppressCellSelection: true,
        suppressColumnVirtualisation: true,
        pagination: true,
        paginationPageSize: 10,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.stockId,
        frameworkComponents: {
          dpBooleanFilter: BooleanFilter,
          dpSelectionFilter: SelectionFilter,
        },
      },
      stockRecords: [],
      selectionRows: [],
      searchModel: {
        billingDateFrom: null,
        supplierShortName: null,
        isStock: true,
      },
      updateDialog: false,
      updateModel: null,
      transferDialog: false,
      transferModel: {
        productCode: null,
        productName: null,
        currentStorageName: null,
        currentStockQuantity: null,
        movingStorageName: null,
        movingQuantity: null,
        movingPlanDate: null,
      },
      stockAmountTotal: 0,
      selectStockAmountTotal: 0,
      crStockAmountTotal: 0,
      mkStockAmountTotal: 0,
      officeStockAmountTotal: 0,
      cafereoDetailColumns: [
        {
          headerName: "商品ステータス",
          field: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoProductStatus.all() },
          valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "商品区分",
          field: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        {
          headerName: "仕入先略称",
          field: "makerName",
        },
        { headerName: "商品ランク", field: "productLank" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "保管場所", field: "storageName" },
        {
          headerName: "在庫総数",
          field: "stockTotalQuantity",
          type: "dpNumericColumn",
          valueGetter: (params) => {
            return params.data.stockQuantity
              ? params.data.stockQuantity
              : 0 + params.data.ensureQuantity
              ? params.data.ensureQuantity
              : 0 + params.data.badQuantity
              ? params.data.badQuantity
              : 0 + params.data.cafereoEntrustQuantity
              ? params.data.cafereoEntrustQuantity
              : 0 + params.data.makerEntrustQuantity
              ? params.data.makerEntrustQuantity
              : 0;
          },
        },
        {
          headerName: "在庫数",
          field: "stockQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "確保在庫数",
          field: "ensureQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "不良在庫数",
          field: "badQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "委託在庫(当社)",
          field: "cafereoEntrustQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "委託在庫(MK)",
          field: "makerEntrustQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "移動予定数",
          field: "movingQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "未出荷受注数", field: "unshipedOrderTotalQuantity", type: "dpNumericColumn" },
        { headerName: "未入荷発注数", field: "unarrivaledPurchaseTotalQuantity", type: "dpNumericColumn" },
        {
          headerName: "在庫金額",
          field: "movingAmount",
          type: "dpNumericColumn",
        },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入価格",
          field: "purchasePrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "受注単位",
          field: "orderUnit",
          type: "dpNumericColumn",
        },
        {
          headerName: "上限数",
          field: "maximumNumber",
          type: "dpNumericColumn",
        },
        { headerName: "メーカー在庫", field: "makerStock", type: "dpCheckmarkColumn" },
        { headerName: "初回締後追加不可", field: "nonAdded", type: "dpCheckmarkColumn" },
        { headerName: "食品", field: "confectionery", type: "dpCheckmarkColumn" },
        { headerName: "賞味期限", field: "sellBy", type: "dpFullDateColumn" },
        { headerName: "ASIN", field: "asin" },
        { headerName: "登録日時", field: "createDatetime", type: "dpFullDateColumn" },
        { headerName: "登録者", field: "createUser" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpFullDateColumn" },
        { headerName: "更新者", field: "updateUser" },
      ],
      badTransferDialog: false,
      badTransferModel: null,
      Storages: Storages,
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails2(this.selectedRow.stockId, this.cafereoDetailColumns, this.gridOptions.api);
    },
    enabledUpdate() {
      return this.selectedRow && this.allowedAction(["C040102"]);
    },
    enabledTransfer() {
      return this.selectedRow && this.allowedAction(["C040103"]);
    },
    enabledCsvExport() {
      return this.allowedAction(["C040104"]);
    },
    enabledBadTransfer() {
      return this.selectedRow && this.allowedAction(["C040105"]);
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        this.searchModel.jancode = this.searchModel.jancodeList
          ? this.searchModel.jancodeList
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        var params = { ...this.searchModel };
        const response = await this.$store.dispatch("stock/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "在庫一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.stocks).length === 0) {
          this.$dialog.warning({
            title: "在庫一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.stocks);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.calcTotalItem();
        this.calcSelectTotalItem();
      } catch (error) {
        console.error("StockSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onUpdateClick() {
      this.updateModel = this.selectedRow;
      this.updateDialog = true;
    },
    onUpdated(stockRecord) {
      this.selectedRow.stockQuantity = stockRecord.stockQuantity;
      this.selectedRow.movingAmount = stockRecord.movingAmount;
      this.selectedRow.updateUser = stockRecord.updateUser;
      this.selectedRow.updateDatetime = stockRecord.updateDatetime;
      this.gridOptions.api.applyTransaction({ update: [this.selectedRow] });
      this.calcTotalItem();
      this.calcSelectTotalItem();
      this.updateDialog = false;
    },
    onTransferClick() {
      this.transferModel = this.selectedRow;
      this.transferDialog = true;
    },
    onTransfered(stockRecord) {
      this.selectedRow.stockQuantity = stockRecord.stockQuantity;
      this.selectedRow.movingQuantity = stockRecord.movingQuantity;
      this.selectedRow.movingAmount = stockRecord.movingAmount;
      this.selectedRow.updateUser = stockRecord.updateUser;
      this.selectedRow.updateDatetime = stockRecord.updateDatetime;
      this.gridOptions.api.applyTransaction({ update: [this.selectedRow] });
      this.calcTotalItem();
      this.calcSelectTotalItem();
      this.transferDialog = false;
    },
    onExportClick() {
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      var params = {
        fileName: "在庫一覧.csv",
        allColumns: false,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      };
      this.gridOptions.api.exportDataAsCsv(params);
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
      this.calcSelectTotalItem();
    },
    calcTotalItem() {
      // 在庫金額の更新
      this.stockAmountTotal = 0;
      this.crStockAmountTotal = 0;
      this.mkStockAmountTotal = 0;
      this.officeStockAmountTotal = 0;
      let rowData = [];
      this.gridOptions.api.forEachNodeAfterFilter((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.stockAmountTotal += row.movingAmount;
        this.crStockAmountTotal += row.purchasePrice * row.cafereoEntrustQuantity;
        this.mkStockAmountTotal += row.purchasePrice * row.makerEntrustQuantity;
        if (parseInt(row.storageCode) == this.Storages.CAFEREO) {
          this.officeStockAmountTotal += row.movingAmount;
        }
      }
    },
    calcSelectTotalItem() {
      // 選択在庫金額の更新
      this.selectionRows = this.gridOptions.api.getSelectedRows();
      this.selectStockAmountTotal = 0;
      for (let row of this.selectionRows) {
        this.selectStockAmountTotal += row.movingAmount;
      }
    },
    onBadTransferClick() {
      this.badTransferModel = this.selectedRow;
      this.badTransferDialog = true;
    },
    onBadTransfered(stockRecord) {
      this.selectedRow.stockQuantity = stockRecord.stockQuantity;
      this.selectedRow.badQuantity = stockRecord.badQuantity;
      this.selectedRow.movingAmount = stockRecord.movingAmount;
      this.selectedRow.updateUser = stockRecord.updateUser;
      this.selectedRow.updateDatetime = stockRecord.updateDatetime;
      this.gridOptions.api.applyTransaction({ update: [this.selectedRow] });
      this.calcTotalItem();
      this.calcSelectTotalItem();
      this.badTransferDialog = false;
    },
  },
};
</script>
