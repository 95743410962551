<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <span class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</span>
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>{{ csvRecords.length }}件中{{ importCount }}件の取り込みが完了しました。</p>
              <p v-if="errorRows.length > 0">送り状情報の取込エラーがあります。確認してください。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete()">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="送状データ取込"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import FileUtils from "../../utils/FileUtils";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "DeliveryInvoiceImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data: () => ({
    step: 1,
    file: null,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnDefs: [
        { headerName: "行番号", field: "rowNumber" },
        { headerName: "出荷実績番号", field: "shipAchivementNo" },
        { headerName: "出荷検品実績番号", field: "shipInspectionAchivementNo" },
        { headerName: "出荷検品年月日", field: "shipInspectionAchivementDate" },
        { headerName: "出荷指示番号", field: "shipInstructionNo" },
        { headerName: "出荷種別", field: "shipType" },
        { headerName: "出荷予定日", field: "shipPlanDate" },
        { headerName: "出荷指示日", field: "shipInstructionDate" },
        { headerName: "受注番号", field: "orderNo" },
        { headerName: "発生日時", field: "accrualDate" },
        { headerName: "発生元部署CD", field: "originalCd" },
        { headerName: "発生元部署名", field: "originalDepartment" },
        { headerName: "注文書番号", field: "purchaseOrderNo" },
        { headerName: "注文書日付", field: "purchaseOrderDate" },
        { headerName: "注文書注釈", field: "purchaseOrderAnnotation" },
        { headerName: "備考", field: "remarks" },
        { headerName: "取引先CD", field: "customerCd" },
        { headerName: "取引先名称", field: "customerName" },
        { headerName: "取引先部署名", field: "customerDepartment" },
        { headerName: "取引先担当者名", field: "customerStaff" },
        { headerName: "取引先郵便番号", field: "customerZipCode" },
        { headerName: "取引先住所", field: "customerAddress" },
        { headerName: "取引先電話番号", field: "customerTelNumber" },
        { headerName: "取引先FAX番号", field: "customerFaxNumber" },
        { headerName: "届先CD", field: "deliveryCd" },
        { headerName: "届先名称", field: "deliveryName" },
        { headerName: "届先部署名", field: "deliveryDepartment" },
        { headerName: "届先担当者名", field: "deliveryStaff" },
        { headerName: "届先郵便番号", field: "deliveryZipCode" },
        { headerName: "届先住所", field: "deliveryAddress" },
        { headerName: "届先電話番号", field: "deliveryTelNumber" },
        { headerName: "届先FAX番号", field: "deliveryFaxNumber" },
        { headerName: "運送会社CD", field: "shipCompanyCd" },
        { headerName: "伝票区分", field: "slipType" },
        { headerName: "伝票番号", field: "slipNo" },
        { headerName: "名寄せ番号", field: "nameIdentificationNo" },
        { headerName: "追加発行作業者ID", field: "additionalIssuerId" },
        { headerName: "追加発行作業者", field: "additionalIssuer" },
        { headerName: "再発行作業者ID", field: "reissueWorkerId" },
        { headerName: "再発行作業者", field: "reissueWorker" },
      ],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
    },
    csvRecords: [],
    validImportForm: null,
    rules: {
      required: Validation.required,
      isNumber: Validation.isNumber,
      maxLength: Validation.maxLength,
      isRate: Validation.isRate,
      isMinNumber: Validation.isMinNumber,
      isCsv: Validation.isCsv,
      isDate: Validation.isDate,
    },
    importCount: 0,
    errorColmuns: [
      { headerName: "行番号", field: "rowNumber" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
    errorRows: [],
  }),
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          const csvText = await FileUtils.readAsText(this.file, true);
          const csvLines = csvText.split("\n").map((line) => line.split(",").map((filed) => filed.trim()));
          this.csvRecords = [];
          csvLines.forEach((line, index) => {
            if (index !== 0 && line.length > 1) {
              this.csvRecords.push({
                rowNumber: index,
                shipAchivementNo: line[0],
                shipInspectionAchivementNo: line[1],
                shipInspectionAchivementDate: line[2],
                shipInstructionNo: line[3],
                shipType: line[4],
                shipPlanDate: line[5],
                shipInstructionDate: line[6],
                orderNo: line[7],
                accrualDate: line[8],
                originalCd: line[9],
                originalDepartment: line[10],
                purchaseOrderNo: line[11],
                purchaseOrderDate: line[12],
                purchaseOrderAnnotation: line[13],
                remarks: line[14],
                customerCd: line[15],
                customerName: line[16],
                customerDepartment: line[17],
                customerStaff: line[18],
                customerZipCode: line[19],
                customerAddress: line[20],
                customerTelNumber: line[21],
                customerFaxNumber: line[22],
                deliveryCd: line[23],
                deliveryName: line[24],
                deliveryDepartment: line[25],
                deliveryStaff: line[26],
                deliveryZipCode: line[27],
                deliveryAddress: line[28],
                deliveryTelNumber: line[29],
                deliveryFaxNumber: line[30],
                shipCompanyCd: line[31],
                slipType: line[32],
                slipNo: line[33],
                nameIdentificationNo: line[34],
                additionalIssuerId: line[35],
                additionalIssuer: line[36],
                reissueWorkerId: line[37],
                reissueWorker: line[38],
              });
            }
          });
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    async onSubmit() {
      this.loadingOn();
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.csvRecords.forEach((record) => {
        var error = this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            rowNumber: record.rowNumber,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      });
      if (isValid) {
        try {
          // 最大件数チェック
          const validDataSizeRet = await RequestUtils.validDataSize("C040702", requestRecords.length, (limitSize) => {
            this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
              timeout: 2300,
            });
          });
          if (!validDataSizeRet) {
            return;
          }
          this.errorRows = [];
          const response = await this.$store.dispatch("shipmentAchievement/shippinglabelImport", {
            rows: requestRecords,
            splitNum: 100,
          });
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.importCount = requestRecords.length;
              this.step++;
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    rowNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              this.importCount = requestRecords.length - this.errorRows.length;
              if (this.errorRows.length > 0) {
                this.$refs.importErrorGrid.open({ records: this.errorRows });
              } else {
                this.$refs.importErrorGrid.close();
              }
              // 画面は完了画面に進める
              this.step++;
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("DeliveryInvoiceImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.importErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];

      this.setValidMessage(this.rules.required(row.shipPlanDate), "出荷予定日", messages);
      this.setValidMessage(this.rules.required(row.orderNo), "受注番号", messages);
      this.setValidMessage(this.rules.required(row.slipNo), "伝票番号", messages);

      this.setValidMessage(this.rules.isDate(row.shipPlanDate), "出荷予定日", messages);

      this.setValidMessage(this.rules.maxLength(12)(row.orderNo), "受注番号", messages);
      this.setValidMessage(this.rules.maxLength(12)(row.customerCd), "取引先CD", messages);
      this.setValidMessage(this.rules.maxLength(12)(row.deliveryCd), "届先CD", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.deliveryName), "届先名称", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.deliveryDepartment), "届先部署名", messages);
      this.setValidMessage(this.rules.maxLength(300)(row.deliveryAddress), "届先住所", messages);
      this.setValidMessage(this.rules.maxLength(15)(row.slipNo), "伝票番号", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    requestFormat(row) {
      return {
        shipPlanDate: this.stringDateFormat(row.shipPlanDate),
        orderNo: this.stringFormat(row.orderNo),
        customerCd: this.stringFormat(row.customerCd),
        deliveryCd: this.stringFormat(row.deliveryCd),
        deliveryName: this.stringFormat(row.deliveryName),
        deliveryDepartment: this.stringFormat(row.deliveryDepartment),
        deliveryAddress: this.stringFormat(row.deliveryAddress),
        slipNo: this.stringFormat(row.slipNo),
        rowNumber: this.numberFormat(row.rowNumber),
      };
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(value).format("YYYY-MM-DD");
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY/MM/DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
};
</script>
