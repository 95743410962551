<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>在庫調整</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="stockForm" v-model="validEditForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="JANCODE"
                :value="updateModel.janCode"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="在庫保管場所"
                :value="updateModel.storageName"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                label="タイトル"
                :value="updateModel.title"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                label="商品名"
                :value="updateModel.productName"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="変更前在庫数"
                :value="updateModel.stockQuantity"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="変更後在庫数"
                :value="updateModel.stockQuantity + Number(updateModel.inputQuantity)"
                filled
                dense
                disabled
                :rules="[rules.isNumber, rules.stockQuantity]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="変更数(+増加/-減少)"
                v-model="updateModel.inputQuantity"
                filled
                dense
                :rules="[rules.required, rules.isNumberMinusAllowed, rules.maxLengthMinusAllowed(8)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="displayFtBillingFlg">
            <v-col cols="12" sm="4">
              <v-switch label="FT宛請求作成" v-model="updateModel.ftBillingFlg" inset dense></v-switch>
            </v-col>
            <v-col cols="12" sm="4" v-if="displayBillingItem">
              <v-text-field
                label="単価"
                v-model="updateModel.price"
                @input="onPriceChanged"
                filled
                dense
                :rules="[rules.price, rules.isNumber, rules.maxLength(8)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" v-if="displayBillingItem">
              <v-text-field
                label="卸掛率"
                v-model="updateModel.rate"
                filled
                dense
                disabled
                :rules="[rules.maxLength(5)]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";

export default {
  name: "StockEdit",
  props: ["inputModel"],
  data() {
    return {
      columnDefs: null,
      updateModel: null,
      validEditForm: null,
      product: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isNumberMinusAllowed: Validation.isNumberMinusAllowed,
        stockQuantity: (value) => this.stockQuantityRules(value),
        price: (value) => this.priceRules(value),
        rate: (value) => this.rateRules(value),
        maxLengthMinusAllowed: Validation.maxLengthMinusAllowed,
      },
    };
  },
  beforeMount() {
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    displayFtBillingFlg() {
      return Number(this.updateModel.inputQuantity) < 0;
    },
    displayBillingItem() {
      return this.updateModel.ftBillingFlg;
    },
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    init(inputModel) {
      this.initUpdateModel(inputModel);
    },
    initUpdateModel(inputModel) {
      this.updateModel = {
        janCode: inputModel.janCode,
        productName: inputModel.productName,
        storageCode: inputModel.storageCode,
        storageName: inputModel.storageName,
        stockQuantity: inputModel.stockQuantity,
        inputQuantity: 0,
        lastUpdateDatetime: inputModel.updateDatetime,
        ftBillingFlg: false,
        price: 0,
        rate: "0.0",
        retailPrice: inputModel.retailPrice,
        stockId: inputModel.stockId,
        title: inputModel.title,
      };
    },
    reset() {
      this.init(this.inputModel);
    },
    onCancelClick() {
      this.$emit("cancel");
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const stockModel = {
            janCode: this.updateModel.janCode,
            storageCode: this.updateModel.storageCode,
            stockQuantity: this.updateModel.stockQuantity + Number(this.updateModel.inputQuantity),
            ftBillingFlg: Boolean(this.updateModel.ftBillingFlg),
            price: this.updateModel.ftBillingFlg ? Number(this.updateModel.price) : 0,
            rate: this.updateModel.ftBillingFlg ? parseFloat(this.updateModel.rate) : 0,
            updateDatetime: this.updateModel.lastUpdateDatetime,
          };
          const response = await this.$store.dispatch("stock/update", stockModel);
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info("在庫情報を更新しました", { timeout: 2300 });
              var stockRecord = response.data.contents.stock;
              stockRecord.stockId = this.updateModel.stockId;
              this.$emit("updated", stockRecord);
              this.reset();
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              var message = this.setError(response.data?.header.messages);
              if (message != "") {
                this.$dialog.warning({
                  title: "在庫調整",
                  text: message,
                  actions: ["OK"],
                });
              }
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: "在庫調整",
                text: response.data?.header.messages.NotItem?.join("<br>"),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("StockEdit::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.stockForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    onPriceChanged() {
      if (this.updateModel.price == null || this.updateModel.price == "") {
        this.updateModel.rate = null;
      } else {
        this.updateModel.rate = ((Number(this.updateModel.price) / Number(this.updateModel.retailPrice)) * 100).toFixed(
          1
        );
      }
    },
    stockQuantityRules(value) {
      if (Number(value) < 0) return "0以上を入力してください";
      return true;
    },
    priceRules(value) {
      if (!this.updateModel.ftBillingFlg) return true;
      var numberCheck = this.rules.isNumber(value);
      if (!numberCheck) return numberCheck;
      return true;
    },
    rateRules(value) {
      if (!this.updateModel.ftBillingFlg) return true;
      var numberCheck = this.rules.isDecimal(value);
      if (!numberCheck) return numberCheck;
      return true;
    },
  },
};
</script>
