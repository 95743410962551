<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-calendar-search</v-icon>入荷予定一覧</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
            <search-conditions @search="onBtnSearch" max-height="100%">
              <v-row dense>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="入荷予定日From"
                    v-model="searchModel.arrivalPlanDateFrom"
                    dense
                    :rules="[rules.arrivalPlanDateFrom]"
                  ></dp-date-picker>
                </v-col>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="入荷予定日To"
                    v-model="searchModel.arrivalPlanDateTo"
                    dense
                    :rules="[rules.arrivalPlanDateTo]"
                  ></dp-date-picker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    v-model="searchModel.jancodeList"
                    class="mx-2"
                    :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                    label="JANコード"
                    rows="1"
                    dense
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    class="mx-2"
                    label="仕入先略称"
                    v-model="searchModel.maker"
                    :rules="[rules.maxLength(150)]"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            icon="mdi-book-arrow-down"
            @click="onDataExportClick"
            :disabled="!enabledDataExport"
            v-if="allowedAction(['C040202'])"
            >翌日入荷データ出力</tooltip-icon-button
          >
          <v-dialog v-model="dataExportDialog" :max-width="dataExportDialogWidth" persistent>
            <delivery-schedule-data-export
              v-if="dataExportDialog"
              :inputModel="selectionRows"
              @dialogResize="dialogResize"
              @cancel="dataExportDialog = false"
              @exported="onDataExportSubmit"
            ></delivery-schedule-data-export>
          </v-dialog>
          <v-divider vertical></v-divider>
          <tooltip-icon-button icon="mdi-download" @click="onCsvExportClick" :disabled="!enabledCsvExport"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <v-row>
          <v-col cols="4"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >発注数合計：{{ this.purchaseQuantityTotal | comma }}</v-card-text
              ></v-card
            ></v-col
          ><v-col cols="4"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >注残数合計：{{ this.remainingQuantityTotal | comma }}</v-card-text
              ></v-card
            ></v-col
          ><v-col cols="4"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >在庫数合計：{{ this.stockQuantityTotal | comma }}</v-card-text
              ></v-card
            ></v-col
          ><v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="scheduleRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          @selection-changed="onSelectionChanged"
          :frameworkComponents="frameworkComponents"
        ></ag-grid-vue>
      </v-col>
      <v-col v-if="shownInfo" id="StockInfos" style="flex-basis: auto; display: flex" :cols="infoMaximum ? 12 : 3">
        <v-divider vertical></v-divider>
        <delivery-schedule-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
        ></delivery-schedule-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import { comma } from "../../filter/NumberFilter";
import { NumericColumn } from "../../models/ag-grid/columnTypes";
import { Cafereo as CafereoColumnDefs } from "../../consts/columns/DeliveryScheduleColumns";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import { statuses as ApiStatus } from "../../libs/api-client";
import DeliveryScheduleInfos from "./../../components/stock/DeliveryScheduleInfos.vue";
import DeliveryScheduleDataExport from "./../../components/stock/DeliveryScheduleDataExport.vue";
import Validation from "../../libs/validation";
import { SelectionFilter } from "../../components/ag-grid/filters";

export default {
  name: "DeliveryScheduleSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    DeliveryScheduleInfos,
    DeliveryScheduleDataExport,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      shownInfo: false,
      infoMaximum: false,
      activeTab: null,
      selectedPageSize: 10,
      gridOptions: {
        columnTypes: {
          dpNumericColumn: NumericColumn,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
          },
        ],
        rowSelection: "multiple",
        suppressCellSelection: true,
        pagination: true,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.identify,
      },
      frameworkComponents: {
        dpSelectionFilter: SelectionFilter,
      },
      scheduleRecords: [],
      selectionRows: [],
      searchModel: {
        arrivalPlanDateFrom: moment().format("YYYY-MM-DD"),
        arrivalPlanDateTo: moment().format("YYYY-MM-DD"),
        jancodes: null,
        maker: null,
      },
      purchaseQuantityTotal: 0,
      remainingQuantityTotal: 0,
      stockQuantityTotal: 0,
      validSeearchForm: null,
      rules: {
        maxLength: Validation.maxLength,
        isJancodeSearchList: Validation.isJancodeSearchList,
        arrivalPlanDateFrom: (value) => this.arrivalPlanDateFromRules(value),
        arrivalPlanDateTo: (value) => this.arrivalPlanDateToRules(value),
      },
      dataExportDialog: false,
      dataExportDialogWidth: "400px",
    };
  },
  filters: { comma },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
    this.gridOptions.columnDefs = this.gridOptions.columnDefs.concat(CafereoColumnDefs);
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.identify, this.gridOptions.api, this.gridOptions.columnApi);
    },
    enabledDataExport() {
      return this.allowedAction(["C040202"]);
    },
    enabledCsvExport() {
      return this.allowedAction(["C040203"]);
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        // jancodeのリスト化
        this.searchModel.jancodes = this.searchModel.jancodeList
          ? this.searchModel.jancodeList
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        var params = { ...this.searchModel };
        const response = await this.$store.dispatch("arrivalPlan/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "入荷予定一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.arrivalPlans).length === 0) {
          this.$dialog.warning({
            title: "入荷予定一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        result.arrivalPlans.forEach((record) => {
          record.identify = record.slipNoList.join("-");
        });
        this.gridOptions.api.setRowData(result.arrivalPlans);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.calcTotalItem();
      } catch (error) {
        console.error("DeliveryScheduleSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onDataExportClick() {
      if (this.selectionRows.length > 0) {
        this.dialogResize(true);
      } else {
        this.dialogResize(false);
      }
      this.dataExportDialog = true;
    },
    dialogResize(selectData) {
      console.log("dialogResize");
      if (selectData) {
        this.dataExportDialogWidth = "1000px";
      } else {
        this.dataExportDialogWidth = "350px";
      }
    },
    onDataExportSubmit(exportFilePath) {
      this.dataExportDialog = false;
      if (exportFilePath != null && exportFilePath != "") {
        window.open(exportFilePath, "_blank");
      }
    },
    onCsvExportClick() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        fileName: `入荷予定一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    calcTotalItem() {
      this.purchaseQuantityTotal = 0;
      this.remainingQuantityTotal = 0;
      this.stockQuantityTotal = 0;
      let rowData = [];
      this.gridOptions.api.forEachNode((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.purchaseQuantityTotal += row.purchaseQuantity ? row.purchaseQuantity : 0;
        this.remainingQuantityTotal += row.remainingQuantity ? row.remainingQuantity : 0;
        this.stockQuantityTotal += row.stockQuantity ? row.stockQuantity : 0;
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    arrivalPlanDateFromRules(value) {
      if (value == null || this.searchModel.arrivalPlanDateTo == null) return true;
      if (moment(new Date(value)).isAfter(this.searchModel.arrivalPlanDateTo))
        return "入荷予定日Toより前の日付を指定してください";
      return true;
    },
    arrivalPlanDateToRules(value) {
      if (value == null || this.searchModel.arrivalPlanDateFrom == null) return true;
      if (moment(new Date(value)).isBefore(this.searchModel.arrivalPlanDateFrom))
        return "入荷予定日Fromより後の日付を指定してください";
      return true;
    },
  },
};
</script>
