import ArrivalStatus from "../../consts/ArrivalStatus";
import { IncludeFilter } from "../../models/ag-grid/columnTypes";
// カフェレオ入荷予定一覧カラム
const Cafereo = [
  { headerName: "伝票番号", field: "slipNoList", hide: true },
  { headerName: "保管場所", field: "storageName" },
  { headerName: "入荷予定日", field: "arrivalPlanDate" },
  {
    headerName: "入荷ステータス",
    field: "status",
    filter: "dpSelectionFilter",
    filterParams: { options: ArrivalStatus.all() },
    valueGetter: (params) => ArrivalStatus.of(params.data.status),
  },
  { headerName: "発売日", field: "releaseDate" },
  { headerName: "仕入先略称", field: "maker" },
  { headerName: "JANCODE", field: "janCode" },
  { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
  { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
  {
    headerName: "Box入数",
    field: "inBoxQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "CT中Box入数",
    field: "inCtBoxQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "CT中pcs入数",
    field: "inCtPcsQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "CTsizeW",
    field: "ctSizeWidth",
    type: "dpNumericColumn",
  },
  {
    headerName: "CTsizeD",
    field: "ctSizeDepth",
    type: "dpNumericColumn",
  },
  {
    headerName: "CTsizeH",
    field: "ctSizeHeight",
    type: "dpNumericColumn",
  },
  {
    headerName: "入荷予定数",
    field: "quantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "発注数",
    field: "purchaseQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "注残数",
    field: "remainingQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "在庫数",
    field: "stockQuantity",
    type: "dpNumericColumn",
  },
];

export default { Cafereo };
export { Cafereo };
