<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title
            ><v-icon>mdi-clipboard-text-search</v-icon
            >{{ isTodayMode ? "当日入荷実績" : " 入荷実績一覧" }}</v-app-bar-title
          >
          <v-spacer></v-spacer>
          <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
            <search-conditions @search="onBtnSearch" max-height="100%">
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-if="isCafereoUser"
                    label="検索上限無し"
                    v-model="searchModel.fetchLimitOverFlg"
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <dp-date-picker
                    v-if="!isTodayMode"
                    type="date"
                    label="入庫日From"
                    v-model="searchModel.receiptDateFrom"
                    :rules="[rules.receiptDateFromRule]"
                    dense
                  ></dp-date-picker>
                </v-col>
                <v-col>
                  <dp-date-picker
                    v-if="!isTodayMode"
                    type="date"
                    label="入庫日To"
                    v-model="searchModel.receiptDateTo"
                    :rules="[rules.receiptDateToRule]"
                    dense
                  ></dp-date-picker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    label="JANコード"
                    v-model="searchModel.janCode"
                    :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                    rows="1"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-if="isCafereoUser"
                    label="商品名"
                    v-model="searchModel.productName"
                    :rules="[rules.maxLength(60)]"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-if="isCafereoUser"
                    label="仕入先略称"
                    v-model="searchModel.makerName"
                    :rules="[rules.maxLength(150)]"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="取込日From"
                    v-model="searchModel.importDateFrom"
                    :rules="[rules.importDateFromRule]"
                    dense
                  ></dp-date-picker>
                </v-col>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="取込日To"
                    v-model="searchModel.importDateTo"
                    :rules="[rules.importDateToRule]"
                    dense
                  ></dp-date-picker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    label="一括取込番号"
                    v-model="searchModel.importNumber"
                    :rules="[rules.maxLength(12), rules.isNumber]"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="isTodayMode && allowedAction(['C040302'])"
            icon="mdi-database-import"
            @click="onImportClick"
            :disabled="!enabledImport"
            >実績取込</tooltip-icon-button
          >
          <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
            <arrival-achievement-import
              @complete="onImportComplete"
              @expand="importDialog.width = $event ? '100%' : '800px'"
              v-if="importDialog.show"
            ></arrival-achievement-import>
          </v-dialog>
          <v-divider v-if="isTodayMode" vertical></v-divider>
          <tooltip-icon-button
            v-if="isTodayMode && allowedAction(['C040303'])"
            :disabled="!enabledRemove"
            icon="mdi-trash-can-outline"
            @click="onRemoveClick"
            >削除</tooltip-icon-button
          >
          <tooltip-icon-button
            v-if="isTodayMode && allowedAction(['C040304'])"
            :disabled="!enabledDifference"
            icon="mdi-database-check"
            @click="onDifferenceClick"
            >差分対処</tooltip-icon-button
          >
          <v-dialog v-model="differenceDialog" max-width="500px" persistent scrollable>
            <arrival-achievment-difference-select
              :inputModel="selectedRow"
              @cancel="differenceDialog = false"
              @updated="onDifferenceSelect"
              v-if="differenceDialog"
            ></arrival-achievment-difference-select>
          </v-dialog>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="isCafereoUser && !isTodayMode && allowedAction(['C040606'])"
            :disabled="!enabledUpdateTransfer"
            icon="mdi-database-edit"
            @click="onUpdateTransferClick"
            >移動編集</tooltip-icon-button
          >
          <v-dialog v-model="updateTransferDialog" max-width="600px" persistent scrollable>
            <stock-move-edit
              :inputModel="selectedRow"
              v-if="updateTransferDialog"
              @cancel="updateTransferDialog = false"
              @updated="onUpdateTransferSubmit"
            ></stock-move-edit>
          </v-dialog>
          <tooltip-icon-button icon="mdi-download" @click="onExportClick" :disabled="!enabledCsvExport"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row v-if="isBusinessError" style="margin-right: 5px; margin-top: 22px">
      <v-btn color="primary" style="margin-left: auto" @click="onBtnExportError">エラーメッセージ出力</v-btn>
    </v-row>
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <v-row>
          <v-col cols="12">
            <v-card v-if="isCafereoUser" elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                入荷数合計：{{ this.receiptQuantityTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="dataRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          @selection-changed="onSelectionChanged"
        ></ag-grid-vue>
      </v-col>
      <v-col
        v-if="shownInfo"
        id="ArrivalAchievementInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <arrival-achievement-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :arrivalId="selectedRow != null ? selectedRow.arrivalId : null"
        ></arrival-achievement-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { NumberValueFormatter } from "../../models/ag-grid/valueFormatters";
import { SelectionFilter } from "../../components/ag-grid/filters";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ArrivalAchievementImport from "../../components/stock/ArrivalAchievementImport.vue";
import ArrivalAchievmentDifferenceSelect from "../../components/stock/ArrivalAchievmentDifferenceSelect.vue";
import Difference from "../../consts/Differences";
import ReceiptTypes from "../../consts/ReceiptTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import { comma } from "../../filter/NumberFilter";
import ArrivalAchievementInfos from "./../../components/stock/ArrivalAchievementInfos.vue";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import Validation from "../../libs/validation";
import StockMoveEdit from "./../../components/stock/StockMoveEdit.vue";

export default {
  name: "ArrivalAchievementSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ArrivalAchievementImport,
    ArrivalAchievmentDifferenceSelect,
    ArrivalAchievementInfos,
    StockMoveEdit,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      shownInfo: false,
      infoMaximum: false,
      activeTab: null,
      gridOptions: {
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
        frameworkComponents: {
          dpSelectionFilter: SelectionFilter,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
          },
        ],
        rowSelection: "multiple",
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: null,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.arrivalId,
      },
      cafereoColumnDefs: [
        { headerName: "入庫日", field: "receiptDate", type: "dpDateColumn", pinned: "left" },
        { headerName: "伝票番号", field: "slipNo", pinned: "left" },
        { headerName: "仕入先CD", field: "supplierCode", pinned: "left" },
        {
          headerName: "入庫区分",
          field: "receiptType",
          valueGetter: (params) => ReceiptTypes.of(params.data.receiptType),
        },
        { headerName: "行No", field: "rowNo", type: "numericColumn", filter: "agNumberColumnFilter" },
        {
          headerName: "仕入先略称",
          field: "makerName",
          // valueGetter: (params) => params.data.makerName.replace("株式会社", ""),
        },
        { headerName: "倉庫CD", field: "warehouseCode" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        { headerName: "入庫数", field: "receiptQuantity", type: "dpNumericColumn" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "仕入掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
        },
        { headerName: "仕入単価", field: "purchasePrice", type: "dpNumericColumn" },
        { headerName: "仕入合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
        { headerName: "保管場所", field: "storageCode" },
        {
          headerName: "入庫差異",
          field: "difference",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          cellStyle: (params) => (params.value == 0 ? {} : { color: "red", fontWeight: "bold" }),
        },
        {
          headerName: "差異対処",
          field: "differenceCope",
          filter: "dpSelectionFilter",
          filterParams: { options: Difference.all() },
          valueGetter: (params) => Difference.of(params.data.differenceCope),
        },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
      cafereoTodayColumnDefs: [
        { headerName: "入庫日", field: "receiptDate", type: "dpDateColumn", pinned: "left" },
        { headerName: "仕入先CD", field: "supplierCode", pinned: "left" },
        {
          headerName: "入庫区分",
          field: "receiptType",
          valueGetter: (params) => ReceiptTypes.of(params.data.receiptType),
        },
        {
          headerName: "仕入先略称",
          field: "makerName",
          // valueGetter: (params) => params.data.makerName.replace("株式会社", ""),
        },
        { headerName: "倉庫CD", field: "warehouseCode" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        { headerName: "発注数", field: "orderPlanQuantity", type: "dpNumericColumn" },
        { headerName: "入庫数", field: "receiptQuantity", type: "dpNumericColumn" },
        {
          headerName: "入庫差異",
          field: "difference",
          pinned: "right",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          cellStyle: (params) => (params.value == 0 ? {} : { color: "red", fontWeight: "bold" }),
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "差異対処",
          field: "differenceCope",
          pinned: "right",
          filter: "dpSelectionFilter",
          filterParams: { options: Difference.all() },
          valueGetter: (params) => Difference.of(params.data.differenceCope),
        },
        { headerName: "登録者", field: "createUser" },
        {
          headerName: "エラーメッセージ",
          field: "errorMessage",
          hide: true,
        },
      ],
      dataRecords: [],
      selectionRows: [],
      searchModel: {
        fetchLimitOverFlg: false,
        receiptDateFrom: null,
        receiptDateTo: null,
        janCode: null,
        productName: null,
        makerName: null,
        importDateFrom: moment().format("YYYY-MM-DD"),
        importDateTo: moment().format("YYYY-MM-DD"),
        importNumber: null,
      },
      importDialog: {
        show: false,
        width: "800px",
      },
      updateTransferDialog: false,
      differenceDialog: false,
      receiptQuantityTotal: 0,
      isBusinessError: false,
      rules: {
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isJancodeSearchList: Validation.isJancodeSearchList,
        receiptDateFromRule: (value) => this.receiptDateFromRules(value),
        receiptDateToRule: (value) => this.receiptDateToRules(value),
        importDateFromRule: (value) => this.importDateFromRules(value),
        importDateToRule: (value) => this.importDateToRules(value),
      },
      validSearchForm: null,
      cafereoDetailColumnDefs: [
        { headerName: "入庫日", field: "receiptDate", type: "dpFullDateColumn", pinned: "left" },
        { headerName: "入庫区分", field: "receiptType" },
        { headerName: "伝票番号", field: "slipNo", pinned: "left" },
        { headerName: "行No", field: "rowNo", type: "numericColumn", filter: "agNumberColumnFilter" },
        { headerName: "仕入先CD", field: "supplierCode", pinned: "left" },
        {
          headerName: "仕入先略称",
          field: "makerName",
          // valueGetter: (params) => params.data.makerName.replace("株式会社", ""),
        },
        { headerName: "倉庫CD", field: "warehouseCode" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "入庫数", field: "receiptQuantity", type: "dpNumericColumn" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "仕入掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
        },
        { headerName: "仕入単価", field: "purchasePrice", type: "dpNumericColumn" },
        { headerName: "仕入合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
        { headerName: "保管場所", field: "storageName" },
        {
          headerName: "入庫差異",
          field: "difference",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          cellStyle: (params) => (params.value == 0 ? {} : { color: "red", fontWeight: "bold" }),
          cellClass: (params) => (params.value == 0 ? [] : ["red--text"]),
        },
        {
          headerName: "差異対処",
          field: "differenceCope",
          filter: "dpSelectionFilter",
          filterParams: { options: Difference.all() },
          valueGetter: (params) => Difference.of(params.data.differenceCope),
        },
        { headerName: "登録日時", field: "createDatetime", type: "dpFullDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
      cafereoDetailTodayColumnDefs: [
        { headerName: "入庫日", field: "receiptDate", type: "dpFullDateColumn", pinned: "left" },
        { headerName: "入庫区分", field: "receiptType" },
        { headerName: "仕入先CD", field: "supplierCode", pinned: "left" },
        {
          headerName: "仕入先略称",
          field: "makerName",
          // valueGetter: (params) => params.data.makerName.replace("株式会社", ""),
        },
        { headerName: "倉庫CD", field: "warehouseCode" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "発注数", field: "orderPlanQuantity", type: "dpNumericColumn" },
        { headerName: "入庫数", field: "receiptQuantity", type: "dpNumericColumn" },
        { headerName: "登録者", field: "createUser" },
        {
          headerName: "入庫差異",
          field: "difference",
          pinned: "right",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          cellStyle: (params) => (params.value == 0 ? {} : { color: "red", fontWeight: "bold" }),
          valueFormatter: NumberValueFormatter(),
          cellClass: (params) => (params.value == 0 ? [] : ["red--text"]),
        },
        {
          headerName: "差異対処",
          field: "differenceCope",
          pinned: "right",
          filter: "dpSelectionFilter",
          filterParams: { options: Difference.all() },
          valueGetter: (params) => Difference.of(params.data.differenceCope),
        },
      ],
    };
  },
  filters: { comma },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
      this.handleResize();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    isTodayMode() {
      console.log(this.$route.name);
      return this.$route.name === "ArrivalAchievementTodaySearch";
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      var columnDefs = null;
      if (this.isTodayMode) {
        columnDefs = this.cafereoDetailTodayColumnDefs;
      } else {
        columnDefs = this.cafereoDetailColumnDefs;
      }
      return getDisplayDetails2(this.selectedRow.arrivalId, columnDefs, this.gridOptions.api);
    },
    enabledImport() {
      return this.allowedAction(["C040302"]);
    },
    enabledRemove() {
      return this.selectionRows.length > 0 && this.allowedAction(["C040303"]);
    },
    enabledDifference() {
      return this.selectedRow && this.allowedAction(["C040304"]);
    },
    enabledCsvExport() {
      const action = this.isTodayMode ? ["C040305"] : ["C040402"];
      return this.allowedAction(action);
    },
    enabledUpdateTransfer() {
      if (this.selectionRows.length === 0) return false;
      return this.selectionRows.every((row) => row.isMoving);
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const params = {
          fetchLimitOverFlg: this.searchModel.fetchLimitOverFlg,
          receiptDateFrom: !this.isTodayMode ? this.searchModel.receiptDateFrom : "",
          receiptDateTo: !this.isTodayMode ? this.searchModel.receiptDateTo : "",
          // JANコードを１行の文字列から配列(半角スペース区切り)へ変更
          janCode: (this.searchModel.jancode = this.searchModel.janCode
            ? this.searchModel.janCode
                .replaceAll(/\n+/g, " ")
                .split(" ")
                .filter((n) => n)
            : null),
          productName: this.searchModel.productName,
          makerName: this.searchModel.makerName,
          shipDateFrom: this.searchModel.importDateFrom,
          shipDateTo: this.searchModel.importDateTo,
          importNumber: this.searchModel.importNumber,
        };
        const response = await this.$store.dispatch("arrivalAchievement/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        let title = "";
        this.isTodayMode ? (title = `当日入荷実績`) : (title = `入荷実績一覧`);
        if (result.over) {
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.arrivalAchievements).length === 0) {
          this.$dialog.warning({
            title: title,
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.arrivalAchievements);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.calcTotalItem();
      } catch (error) {
        console.error("ArrivalAchievementSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onExportClick() {
      var allColumnIds = [];
      var lastColIndex = 0;
      if (this.isTodayMode) {
        lastColIndex = 14;
      } else {
        lastColIndex = 21;
      }
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && lastColIndex > idx && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        columnKeys: allColumnIds,
        onlySelected: this.selectionRows.length > 0,
        fileName: this.isTodayMode ? `当日入荷実績` : `入荷実績一覧`,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    async onRemoveClick() {
      let messageText = `選択された実績を削除します`;
      if (this.selectionRows.length > 1) {
        messageText += ` <small>(${this.selectionRows.length} 件)</small>`;
      }
      const ok = await this.$dialog.confirm({ title: "入荷実績", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          var requestContents = [];
          for (let row of this.selectionRows) {
            requestContents.push({
              arrivalId: row.arrivalId,
              updateDatetime: row.updateDatetime,
            });
          }
          const response = await this.$store.dispatch("arrivalAchievement/delete", {
            arrivalAchievements: requestContents,
          });
          let contents = response.data?.contents?.arrivalAchievements;
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.isBusinessError = false;
              this.gridOptions.columnDefs[14].hide = true;
              this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
              for (let row of this.selectionRows) {
                let arrival = contents.find((r) => r.arrivalId === row.arrivalId);
                row.receiptDate = arrival.receiptDate;
                row.receiptType = arrival.receiptType;
                row.supplierCode = arrival.supplierCode;
                row.makerName = arrival.makerName;
                row.warehouseCode = arrival.warehouseCode;
                row.janCode = arrival.janCode;
                row.title = arrival.title;
                row.productName = arrival.productName;
                row.orderPlanQuantity = arrival.orderPlanQuantity;
                row.receiptQuantity = arrival.receiptQuantity;
                row.difference = arrival.difference;
                row.differenceCope = arrival.differenceCope;
                row.createUser = arrival.createUser;
                row.updateDatetime = arrival.updateDatetime;
              }
              this.$dialog.notify.info(`入荷実績情報を削除しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              this.isBusinessError = true;
              this.gridOptions.columnDefs[14].hide = false;
              this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
              for (let row of this.selectionRows) {
                let displayMessage = "";
                Object.keys(error.messages).forEach((key) => {
                  if (row.arrivalId == key) {
                    displayMessage += error.messages[key]?.join("<br>");
                  }
                });
                row.errorMessage = displayMessage;
              }
              this.$dialog.notify.error(`入荷実績情報の削除エラーがあります。確認してください。`, { timeout: 2300 });
              break;
            default:
              this.redirectError();
              break;
          }
          this.gridOptions.api.applyTransaction({ update: this.selectionRows });
        } catch (error) {
          console.error("ArrivalAchievementSearch::onRemoveClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onImportClick() {
      this.importDialog.show = true;
    },
    onImportComplete(canceled) {
      this.importDialog.show = false;
      console.log("ArrivalAchievementSearch::onImportComplete", "canceled", canceled);
      if (!canceled) {
        this.onSearchClick();
      }
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    onDifferenceClick() {
      this.differenceDialog = true;
    },
    onDifferenceSelect(arrivalAchievementRecord) {
      this.selectedRow.differenceCope = arrivalAchievementRecord.differenceCope;
      this.selectedRow.updateDatetime = arrivalAchievementRecord.updateDatetime;
      this.gridOptions.api.applyTransaction({ update: [this.selectedRow] });
      this.differenceDialog = false;
    },
    calcTotalItem() {
      this.receiptQuantityTotal = 0;
      let rowData = [];
      this.gridOptions.api.forEachNode((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.receiptQuantityTotal += row.receiptQuantity ? row.receiptQuantity : 0;
      }
    },
    onBtnExportError() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({ columnKeys: allColumnIds, fileName: "エラーメッセージ.csv" });
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    receiptDateFromRules(value) {
      if (this.isTodayMode || value == null || this.searchModel.receiptDateTo == null) return true;
      if (moment(new Date(value)).isAfter(this.searchModel.receiptDateTo))
        return "入庫日Toより前の日付を指定してください";
      return true;
    },
    receiptDateToRules(value) {
      if (this.isTodayMode || value == null || this.searchModel.receiptDateFrom == null) return true;
      if (moment(new Date(value)).isBefore(this.searchModel.receiptDateFrom))
        return "入庫日Fromより後の日付を指定してください";
      return true;
    },
    importDateFromRules(value) {
      if (value == null || this.searchModel.importDateTo == null) return true;
      if (moment(new Date(value)).isAfter(this.searchModel.importDateTo))
        return "取込日Toより前の日付を指定してください";
      return true;
    },
    importDateToRules(value) {
      if (value == null || this.searchModel.importDateFrom == null) return true;
      if (moment(new Date(value)).isBefore(this.searchModel.importDateFrom))
        return "取込日Fromより後の日付を指定してください";
      return true;
    },
    onUpdateTransferClick() {
      this.updateTransferDialog = true;
    },
    onUpdateTransferSubmit(shipmentRecord) {
      this.selectedRow.warehouseCode = shipmentRecord.storageCode;
      this.selectedRow.storageCode = shipmentRecord.storageName;
      this.selectedRow.orderPlanQuantity = shipmentRecord.orderQuantity;
      this.selectedRow.shipDate = shipmentRecord.shipDate;
      this.selectedRow.updateDatetime = shipmentRecord.updateDatetime;
      this.selectedRow.updateUser = shipmentRecord.updateUser;
      this.gridOptions.api.applyTransaction({ update: [this.selectedRow] });
      this.updateTransferDialog = false;
    },
    onUpdateTransferCancel() {
      this.updateTransferDialog = false;
    },
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
    if (this.isTodayMode) {
      this.gridOptions.columnDefs.push(...this.cafereoTodayColumnDefs);
    } else {
      this.gridOptions.columnDefs.push(...this.cafereoColumnDefs);
    }
  },
};
</script>
