<template>
  <v-container fluid style="height: 100%">
    <simple-history :histories="histories" :functionCategory="functionCategory"></simple-history>
  </v-container>
</template>

<script>
import SimpleHistory from "../common/SimpleHistory.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
export default {
  name: "ShipmentAchievementHistory",
  props: ["shippingInstructionId"],
  components: {
    SimpleHistory,
  },
  data() {
    return {
      histories: {},
      functionCategory: "",
    };
  },
  watch: {
    shippingInstructionId(shippingInstructionId) {
      this.init(shippingInstructionId);
    },
  },
  mounted() {
    this.init(this.shippingInstructionId);
  },
  methods: {
    async init(shippingInstructionId) {
      try {
        this.loadingOn();
        this.functionCategory = "shipment";
        const response = await this.$store.dispatch("shipmentAchievement/history", {
          shippingInstructionId: shippingInstructionId,
        });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        this.histories = response.data.contents.histories;
      } catch (error) {
        console.error("OrderHistory::mounted", error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
