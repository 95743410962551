<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-transfer</v-icon>在庫移動編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="transferForm" v-model="validTransferForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="JANCODE"
                :value="transferModel.janCode"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                label="タイトル"
                :value="transferModel.title"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                label="商品名"
                :value="transferModel.productName"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="在庫保管場所"
                :value="transferModel.currentStorageName"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="現在庫数"
                :value="transferModel.currentStockQuantity"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="在庫数(不良)"
                :value="transferModel.badQuantity"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row dense>
            <v-col cols="5">
              <dp-date-picker
                type="date"
                :label="!inputModel.arrivalId ? '移動予定日(出荷予定日)' : '移動予定日(入荷予定日)'"
                v-model="transferModel.movingPlanDate"
                filled
                dense
                :rules="[rules.required, rules.isTodayAfter]"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="9">
              <v-select
                label="移動先保管場所"
                v-model="transferModel.movingStorageCode"
                :items="storageList"
                filled
                dense
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                label="良品不良品区分"
                v-model="transferModel.productConditionType"
                :items="ftProductConditionTypeList"
                filled
                dense
                :rules="[rules.required]"
                attach
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="移動数量"
                v-model="transferModel.movingQuantity"
                filled
                dense
                :rules="[rules.required, rules.isNumber, rules.maxLength(8), rules.movingQuantity]"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12">
              <v-text-field
                v-if="!inputModel.arrivalId"
                label="備考"
                v-model="transferModel.remarks"
                filled
                dense
                :rules="[rules.maxLength(30)]"
              ></v-text-field>
            </v-col> -->
          </v-row>
          <v-row dense v-if="!inputModel.arrivalId">
            <v-col cols="12">
              <v-checkbox class="float-right" label="出荷希望" v-model="transferModel.isReady" dense></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onTransferClick">登録</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import Storages from "../../consts/Storages";
import FtProductConditionType from "../../consts/FtProductConditionTypes";
import moment from "moment";

export default {
  name: "StockMoveEdit",
  props: ["inputModel"],
  data() {
    return {
      transferModel: {
        shippingInstructionId: null,
        productCode: null,
        janCode: null,
        title: null,
        productName: null,
        storageCode: null,
        currentStorageName: null,
        currentStockQuantity: 0,
        movingPlanDate: null,
        movingStorageCode: null,
        movingQuantity: 0,
        updateDatetime: null,
        isReady: false,
        remarks: null,
      },
      validTransferForm: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        movingQuantity: (value) => this.movingQuantityRules(value),
        movingPlanDate: (value) => this.movingPlanDateRules(value),
        isTodayAfter: Validation.isTodayAfter,
      },
      storageList: Storages.all(),
      ftProductConditionTypeList: FtProductConditionType.all(),
    };
  },
  beforeMount() {
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    init(inputModel) {
      this.initTransferModel(inputModel);
    },
    async initTransferModel(inputModel) {
      if (inputModel) {
        console.log(inputModel);
        var params = { janCode: inputModel.janCode, storageCode: inputModel.storageCode };
        if (inputModel.arrivalId) {
          params.storageCode = inputModel.movingWarehouseCode;
        }
        const response = await this.$store.dispatch("stock/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        this.transferModel = {
          shippingInstructionId: inputModel.shippingInstructionId,
          arrivalId: inputModel.arrivalId,
          productCode: inputModel.productCode,
          janCode: inputModel.janCode,
          title: inputModel.title,
          productName: inputModel.productName,
          storageCode: !inputModel.arrivalId ? inputModel.storageCode : inputModel.movingWarehouseCode,
          currentStorageName: !inputModel.arrivalId ? inputModel.storageName : inputModel.movingWarehouseName,
          currentStockQuantity:
            response.data.contents.stocks.length > 0 ? response.data.contents.stocks[0].stockQuantity : 0,
          badQuantity: response.data.contents.stocks.length > 0 ? response.data.contents.stocks[0].badQuantity : 0,
          movingPlanDate: inputModel.shipDate,
          movingStorageCode: !inputModel.arrivalId
            ? Number(inputModel.movingWarehouseCode)
            : Number(inputModel.warehouseCode),
          movingQuantity: !inputModel.arrivalId ? inputModel.orderQuantity : inputModel.orderPlanQuantity,
          productConditionType: inputModel.productConditionType,
          updateDatetime: inputModel.updateDatetime,
          isReady: inputModel.isReady,
          remarks: inputModel.remarks,
        };
        if (inputModel.productConditionType === 10) {
          this.transferModel.currentStockQuantity += inputModel.orderQuantity;
        } else {
          this.transferModel.badQuantity += inputModel.orderQuantity;
        }
      } else {
        this.transferModel = {
          shippingInstructionId: null,
          arrivalId: null,
          productCode: null,
          title: null,
          productName: null,
          storageCode: null,
          currentStorageName: null,
          currentStockQuantity: 0,
          movingPlanDate: null,
          movingStorageCode: null,
          movingQuantity: 0,
          updateDatetime: null,
          isReady: false,
        };
      }
    },
    reset() {
      this.init(this.inputModel);
    },
    onCancelClick() {
      this.$emit("cancel");
    },
    async onTransferClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const transferModel = {
            shippingInstructionId: this.transferModel.shippingInstructionId,
            arrivalId: this.transferModel.arrivalId,
            shipDate: this.transferModel.movingPlanDate,
            productCode: this.transferModel.productCode,
            storageCode: String(this.transferModel.storageCode),
            stockQuantity: Number(this.transferModel.movingQuantity),
            movingStorageCode: String(this.transferModel.movingStorageCode),
            remarks: this.transferModel.remarks ? this.transferModel.remarks : "",
            isReady: this.transferModel.isReady,
            updateDatetime: this.transferModel.updateDatetime,
          };
          const response = await this.$store.dispatch("transfer/update", transferModel);
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info("移動指示を更新しました", { timeout: 2300 });
              var stockRecord = response.data.contents.shipment;
              this.$emit("updated", stockRecord);
              this.reset();
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              var message = this.setError(response.data?.header.messages);
              if (message != "") {
                this.$dialog.warning({
                  title: "在庫移動編集",
                  text: message,
                  actions: ["OK"],
                });
              }
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: "在庫移動編集",
                text: response.data?.header.messages.NotItem?.join("<br>"),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("StockMoveEdit::onTransferClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.transferForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    movingQuantityRules(value) {
      if (value == null) return true;
      if (Number(value) < 0) return "0以上を入力してください";
      if (this.transferModel.productConditionType === 10) {
        if (Number(value) > Number(this.transferModel.currentStockQuantity)) return "現在庫数以下を入力してください";
      } else {
        if (Number(value) > Number(this.transferModel.badQuantity)) return "不良在庫数以下を入力してください";
      }
      return true;
    },
    movingPlanDateRules(value) {
      if (value == null) return true;
      if (!moment(value).isSameOrBefore(moment(new Date()))) return "現在日以降の日付を入力してください";
      return true;
    },
    setError(responseMessage) {
      var messages = [];
      var message = "";
      if (responseMessage?.NotItem) {
        messages.push(responseMessage.NotItem?.join("<br>"));
      }
      if (messages.length > 0) message = messages.join("<br>");
      return message;
    },
  },
};
</script>
