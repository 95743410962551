<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <span class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</span>
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>
                {{ csvRecords.length }}件中{{ importCount }}件の取り込みが完了しました。 (一括取込番号：{{
                  importNumber
                }})
              </p>
              <p v-if="errorRows.length > 0">出荷実績情報の取込エラーがあります。確認してください。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete(false)">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="出荷実績一括取込"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import FileUtils from "../../utils/FileUtils";
import RequestUtils from "../../utils/RequestUtils";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import axios from "axios";

export default {
  name: "ShipmentAchievementImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data: () => ({
    step: 1,
    file: null,
    bulk_id: null,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      columnDefs: [
        { headerName: "行番号", field: "rowNumber" },
        { headerName: "出荷予定日", field: "shipmentPlanDate", type: "dpDateColumn" },
        { headerName: "配送指定日", field: "specifiedDeliveryDate", type: "dpDateColumn" },
        { headerName: "受注番号", field: "orderId" },
        { headerName: "出荷取引先CD", field: "receiptCustomerCode" },
        { headerName: "出荷取引先名", field: "shipmentCustomerName" },
        { headerName: "取引先部署名", field: "customerDepartmentName" },
        { headerName: "届先CD", field: "shipCode" },
        { headerName: "届先名", field: "shipName" },
        { headerName: "届先部署名", field: "shipDepartmentName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "出荷指示数量", field: "shippingInstructionQuantity", type: "dpNumericColumn" },
        { headerName: "出荷取引先JANCODE", field: "receiptCustomerJanCode" },
        { headerName: "本体金額", field: "amount", type: "dpNumericColumn" },
        { headerName: "明細_フリーエリア２", field: "detailsFreeArea2", type: "dpNumericColumn" },
        { headerName: "明細_フリーエリア４", field: "detailsFreeArea4" },
        { headerName: "明細_フリーエリア５", field: "detailsFreeArea5" },
        { headerName: "出荷指示明細番号", field: "shippingInstructionDetailNumber" },
      ],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
    },
    csvRecords: [],
    importNumber: null,
    validImportForm: null,
    rules: {
      required: Validation.required,
      isNumber: Validation.isNumber,
      isCsv: Validation.isCsv,
      maxLength: Validation.maxLength,
    },
    importCount: 0,
    errorColmuns: [
      { headerName: "行番号", field: "rowNumber" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
    errorRows: [],
  }),
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          const csvText = await FileUtils.readAsText(this.file, true);
          const csvLines = csvText.split("\n").map((line) => line.split(",").map((filed) => filed.trim()));
          this.csvRecords = [];
          csvLines.forEach((line, index) => {
            if (index !== 0 && line.length > 1) {
              this.csvRecords.push({
                rowNumber: index,
                shipmentPlanDate: line[0],
                specifiedDeliveryDate: line[1],
                orderId: line[2],
                receiptCustomerCode: line[3],
                shipmentCustomerName: line[4],
                customerDepartmentName: line[5],
                shipCode: line[6],
                shipName: line[7],
                shipDepartmentName: line[8],
                janCode: line[9],
                shippingInstructionQuantity: line[10],
                receiptCustomerJanCode: line[11],
                amount: line[12],
                detailsFreeArea2: line[13],
                detailsFreeArea4: line[14],
                detailsFreeArea5: line[15],
                shippingInstructionDetailNumber: line[16],
              });
            }
          });
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    async onSubmit() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.csvRecords.forEach((record) => {
        var error = this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            rowNumber: record.rowNumber,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      });
      if (isValid) {
        try {
          this.errorRows = [];
          this.loadingOn();
          this.importNumber = null;
          let uploadUrl = "";

          // 最大件数チェック
          const validDataSizeRet = await RequestUtils.validDataSize("C040704", requestRecords.length, (limitSize) => {
            this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
              timeout: 2300,
            });
            this.loadingOff();
          });
          if (!validDataSizeRet) {
            return;
          }

          // 100件毎にスライス
          const response = await this.$store.dispatch("shipmentAchievement/import", {
            rows: requestRecords,
            splitNum: 100,
          });
          let error = response.data?.header;
          uploadUrl = response.data.contents?.url;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.importNumber = response.data.contents.importNumber;
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              this.importNumber = this.importCount > 0 ? response.data.contents.importNumber : this.importNumber;
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    rowNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }

          this.importCount = requestRecords.length - this.errorRows.length;
          if (uploadUrl) this.csvUpload(uploadUrl);
          if (this.errorRows.length > 0) {
            this.$refs.importErrorGrid.open({ records: this.errorRows });
          } else {
            this.$refs.importErrorGrid.close();
            this.step++;
          }
        } catch (error) {
          console.error("ShipmentAchievementImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.importErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];

      this.setValidMessage(this.rules.required(row.orderId), "受注番号", messages);
      this.setValidMessage(this.rules.required(row.shippingInstructionQuantity), "出荷指示数量", messages);
      this.setValidMessage(this.rules.required(row.shippingInstructionDetailNumber), "出荷指示明細番号", messages);

      this.setValidMessage(this.isDate(row.shipmentPlanDate), "出荷予定日", messages);
      this.setValidMessage(this.isDate(row.specifiedDeliveryDate), "配送指定日", messages);

      this.setValidMessage(this.rules.isNumber(row.orderId), "受注番号", messages);
      this.setValidMessage(this.rules.isNumber(row.shippingInstructionQuantity), "出荷指示数量", messages);
      this.setValidMessage(this.rules.isNumber(row.amount), "本体金額", messages);
      this.setValidMessage(this.rules.isNumber(row.shippingInstructionDetailNumber), "出荷指示明細番号", messages);

      this.setValidMessage(this.rules.maxLength(9)(row.orderId), "受注番号", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.receiptCustomerCode), "出荷取引先CD", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.shipmentCustomerName), "出荷取引先名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.customerDepartmentName), "取引先部署名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.shipCode), "届先CD", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.shipName), "届先名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.shipDepartmentName), "届先部署名", messages);
      this.setValidMessage(this.rules.maxLength(13)(row.janCode), "JANCODE", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.shippingInstructionQuantity), "出荷指示数量", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.receiptCustomerJanCode), "出荷取引先JANCODE", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.amount), "本体金額", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.detailsFreeArea2), "明細_フリーエリア２", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.detailsFreeArea4), "明細_フリーエリア４", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.detailsFreeArea5), "明細_フリーエリア５", messages);
      this.setValidMessage(this.rules.maxLength(9)(row.shippingInstructionDetailNumber), "出荷指示明細番号", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    requestFormat(row) {
      return {
        customerDepartmentName: this.stringFormat(row.customerDepartmentName),
        orderId: this.stringFormat(row.orderId),
        shippingInstructionQuantity: this.numberFormat(row.shippingInstructionQuantity),
        receiptCustomerCode: this.stringFormat(row.receiptCustomerCode),
        receiptCustomerJanCode: this.stringFormat(row.receiptCustomerJanCode),
        shipmentCustomerName: this.stringDateFormat(row.shipmentCustomerName),
        shipmentPlanDate: this.stringDateFormat(row.shipmentPlanDate),
        janCode: this.stringFormat(row.janCode),
        shipCode: this.stringFormat(row.shipCode),
        shipDepartmentName: this.stringFormat(row.shipDepartmentName),
        shipName: this.stringDateFormat(row.shipName),
        specifiedDeliveryDate: this.stringDateFormat(row.specifiedDeliveryDate),
        amount: this.numberFormat(row.amount),
        detailsFreeArea2: this.stringFormat(row.detailsFreeArea2),
        detailsFreeArea4: this.stringFormat(row.detailsFreeArea4),
        detailsFreeArea5: this.stringFormat(row.detailsFreeArea5),
        shippingInstructionDetailNumber: this.numberFormat(row.shippingInstructionDetailNumber),
        rowNumber: this.numberFormat(row.rowNumber),
      };
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(value).format("YYYY-MM-DD");
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY/MM/DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    async csvUpload(url) {
      // CSVファイルのアップロード
      const buffer = await FileUtils.readAsArrayBuffer(this.file);
      const response = await axios.put(url, buffer, { headers: { "Content-Type": this.file.type } });
      console.log(response);
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
};
</script>
